@import '../../../../styles/customMediaQueries.css';
.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.titleLanding {
  font-size: 79px;
  line-height: 1.1;
  letter-spacing: -0.5px;
  font-weight: 800;
  text-wrap: balance;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;

  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* center */
    margin-left: auto;
    margin-right: auto;
  }

  & > p {
    /* center */
    margin-left: auto;
    margin-right: auto;
  }
}

.alignRight {
  text-align: right;
  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* push to right */
    margin-left: auto;
  }

  & > p {
    /* push to right */
    margin-left: auto;
  }
}

.card {
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-5px);
}

.cardText {
  padding: 16px;
}
.align {
  text-align: left;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.ctaButtonOutlined {
  composes: align;
  display: inline-block;
  padding: 15px 25px;
  font-size: 15px;
  border-radius: 10px;
  color: white;
  border-width: 2px;
  border-style: solid;
  text-decoration: none !important;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 700;
  margin-top: 24px;

  &:hover {
    text-decoration: none;
    background-color: white;
    border-color: transparent;
  }
}
.ctaButtonOutlinedGreen {
  composes: align;
  display: inline-block;
  padding: 15px 25px;
  font-size: 15px;
  border-radius: 10px;
  color: white;
  border-width: 2px;
  border-style: solid;
  text-decoration: none !important;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 700;
  margin-top: 24px;

  &:hover {
    text-decoration: none;
    background-color: white;
    color: #2a623d !important;
    border-color: transparent;
  }
}

.artistSectionText {
  margin-left: 14vw; /* Ajusta este valor según necesites */
}

.video {
  width: 100%;
  max-width: 400px;
  /* width: 400px;
  height: 400px; */
  height: auto;
  object-fit: contain;
  border: 4px solid rgba(255, 255, 255, 0.85);
  border-radius: 80px; /* Opcional: para bordes redondeados */
}
@media (max-width: 950px) {
  .artistSectionText {
    margin-left: 0px; /* Margen más pequeño en móviles */
  }
  .video {
    max-width: 300px;
    height: auto;
    border-width: 4px; 
  }
}

@media (max-width: 450px) {
  .video {
    max-width: 200px;
    border-radius: 40px;
  }
}

.titleJoin {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.33;
  font-weight: bold;
  color: var(--colorGrey900);
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  text-align: center;
  font-size: 32px;
}
.stuviLinkJoin {
  color: var(--marketplaceColor);
  font-style: italic;
  text-align: center;
  margin-top: 0;
  font-size: 30px;
  margin-bottom: 15px;
}
.animatedButtonContainerCentered {
  display: -webkit-box; /* Prefijo para Safari */
  display: -ms-flexbox; /* Prefijo para IE */
  display: flex;
  justify-content: center;
}

.nav_cta_wrap_dark {
  position: relative;
  display: inline-block;
  margin-top: 20px !important;
  max-width: 300px;
  width: 100%;

  a {
    color: white !important;
  }

  .cta_jag_blue {
    background-color: var(--marketplaceColor);
    font-weight: 700;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 10px;
    text-decoration: none;
    color: white;
    border: 2px white solid;
    text-align: center;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    margin-left: 0 !important;
    width: 100%;
    max-width: 300px;
  }

  .animation_dark {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0); /* Prefijo para Safari */
    -ms-transform: translate(-50%, -50%) scale(0); /* Prefijo para IE */
    transform: translate(-50%, -50%) scale(0);
    width: 130%;
    height: 170%;
    max-width: 400px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  &:hover {
    .cta_jag_blue {
      background-color: transparent;
      color: var(--marketplaceColor) !important;
    }
    .animation_dark {
      -webkit-transform: translate(-50%, -50%) scale(1); /* Prefijo para Safari */
      -ms-transform: translate(-50%, -50%) scale(1); /* Prefijo para IE */
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
}

@media (max-width: 650px) {
  .ctaButtonOutlined, .ctaButtonOutlinedGreen {
    display: -webkit-box; /* Prefijo para Safari */
    display: -ms-flexbox; /* Prefijo para IE */
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .nav_cta_wrap_dark {
    width: 100%;
    max-width: 300px;
    margin: 20px auto !important;
    
    .cta_jag_blue {
      width: 100%;
      max-width: 300px;
    }
    .cta_jag_blue:hover {
      background-color: white;
    }
    .animation_dark {
      width: 0%;
    }
  }

  .titleLanding {
    font-size: 55px;
    text-align: center;
  }
}

@media (max-width: 780px) {
  .titleLanding {
    font-size: 45px;
    text-align: left;
  }
}

@media (max-width: 450px) {
  .titleLanding {
    text-align: center;
  }
}

@media (max-width: 320px) {
  .titleLanding {
    font-size: 38px;
  }
}